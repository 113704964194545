<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import IconButton, { Icon } from "@smui/icon-button";
  import Kitchen from "@smui/snackbar/kitchen";
  import Tooltip, { Content as TooltipContent, Wrapper } from "@smui/tooltip";
  import Accordion, {
    Header as AccordionHeader,
    Content,
    Panel,
  } from "@smui-extra/accordion";
  import { differenceInMilliseconds, isFuture, isToday } from "date-fns";
  import { compareAsc } from "date-fns/compareAsc";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount, tick } from "svelte";
  import { flip } from "svelte/animate";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import deleteBringOutPackage from "~/assets/images/deleteBringOutPackage.gif";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import addressUtils from "~/libs/addressUtils";
  import { beep, destroyAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    DeliveryRecordTypes,
    GOOGLE_MAP_BASE_URL,
    NotificationCategory,
    QrHomeTypes,
    QrScanModes,
    STATUS_DELIVERED,
    STATUS_OUT_FOR_DELIVERY,
    STATUS_REQUESTING_FOR_RETURN,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import desiredDateTime from "~/libs/desiredDateTime";
  import geolocator from "~/libs/geolocator";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import {
    calcBearing,
    calcDistance,
    formatDistanceInMeters,
    parseAddress,
  } from "~/libs/mapUtils";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { CodeType } from "~/libs/qrCodeScanner";
  import {
    currentPositionStore,
    needToGettingKnowledge,
    updateCenter,
  } from "~/libs/stores";
  import swipeable from "~/libs/swipeable";
  import { reserveUpdateDeliveryRecordsAndSyncBackend } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import {
    formatTrackingNumber,
    parseCodabarEmbeddedTrackingNumber,
    parseQRCodeEmbeddedTrackingNumber,
  } from "~/libs/trackingNumberUtils";
  import { disposeIframe } from "~/libs/utils";
  import PackageInformation from "~/pages/OutForDeliveryWithQrCodeScan/PackageInformation.svelte";

  /**
   * @typedef {{
   *   id: number,
   *   trackingNumber: string,
   *   numberOfPackages: number,
   *   updateTime: string,
   *   isManuallyInputted: boolean,
   *   addressNotFound?: boolean,
   *   directionAndDistance?: {direction: string, distance: string},
   *   needToCancelReturn?: boolean,
   *   dateLabelType?: number,
   *   timeFrame?: string,
   *   address: string,
   *   correctedAddress?: string,
   *   receiverName?: string,
   *   desiredDate?: string,
   *   desiredTime?: string,
   *   cashOnDeliveryAmount?: number,
   *   packageDropPlace?: number,
   *   extraEvent?: Array<import("~/libs/commonTypes").ExtraEvent>,
   *   numberOfDeliveryAttempts?: number,
   *   redeliveryContext?: {adjustedRedeliveryDatetime: {date: string, timeFrame: string}},
   *   specifiedPickupDatetime?: {desiredRedeliveryDatetime: {date: string, timeFrame: string}, availablePickupDatetime: Array<{date: string, timeFrame: string}>},
   *   afterTommorowChecked: boolean,
   *   multipleBoxesChecked: boolean,
   *   returnStatus?: number,
   *   returnReason?: number,
   *   bringOutReturnRequestPackage: boolean,
   *   returnChecked: boolean,
   *   isInDeliveredList: boolean,
   *   inDeliveredListChecked: boolean,
   *   possibleIncorrectAddress: boolean,
   *   isDeleteFlag: boolean,
   * }} TrackingData
   */

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const centerId = $updateCenter ? $updateCenter.split("/")[0] : null;
  const centerName = $updateCenter ? $updateCenter.split("/")[1] : null;

  /** @type {Set<string>} */
  const decodedTextList = new Set();

  /** @type {QrCodeScanner} */
  let qrCodeScanner;

  /** @type {Kitchen} */
  let kitchenSnackbar;

  /** @type {Array<TrackingData>} */
  let trackingList = [];
  /** @type {Array<TrackingData>} */
  let trackingListStack = [];
  /** 現在地 @type {GeolocationCoordinates} */
  let currentPosition;
  /** スタック内荷物一覧ダイアログ @type {ConfirmDialog} */
  let stackDialog;
  let showSortAndRegisterDialog = false;
  let showDeadlineAndDesiredDateDialog = false;
  let isScanningMode = true;
  /** 配達リストに登録されている荷物の送り状番号一覧 @type {Set<string>} */
  let currentShippingSet = new Set();
  /** スキャン中(未スタック)のデータ件数 @type {number} */
  let unstackedScanDataNum = 0;
  /** スタック済みのデータ件数 @type {number} */
  let stackedScanDataNum = 0;

  /** Googleマップのiframeを事前キャッシュするためのURL @type {string} */
  let googleMapCacheUrl;

  /** @type {0 | 1 | 2} */
  let sortRadio = 0;

  /** @type {import("svelte/store").Unsubscriber} */
  let currentPositionStoreUnsubscriber;

  /** 戻る確認ダイアログ @type {ConfirmDialog} */
  let backConfirmDialog;

  /** 配達完了済み荷物の送り状番号 @type {string} */
  let deliveredPackageTrackingNumber;

  /** 指定日時超過の荷物の個数 @type {number} */
  let numberOfOverduePackages = 0;

  /** 本日指定の荷物の個数 @type {number} */
  let numberOfTodayPackages = 0;

  /** 配達完了リスト @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
  let deliveredPackageList = [];

  /** 最新削除分の荷物に付けられた日付ラベルの種類 @type {number} */
  let deletedDateLabelType;

  /** データ破棄確認後に実行する処理 @type {() => void} */
  let functionAfterDiscard;

  /** コードスキャンおよびスキャン後の後処理を実行している数（実行中の「次へ」ボタンの制御に使用） */
  let numberOfScanInProgress = 0;

  /** スキャン失敗後に手入力案内メッセージを表示するまでのカウント中の場合にtrue @type {boolean} */
  let isCountingAfterFailedScan = false;

  /** @type {Date} 現在の日時 */
  const now = new Date();

  /** @type {Date} 現在の日付に加え、時間をデフォルトの9時に設定したもの */
  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    9,
    0,
    0,
    0,
  );

  /** 各荷物の確認事項（翌日以降指定、返品対象、一度配達済み、複数個口）が全て確認済みかどうか @type {boolean}*/
  let allChecked = true;

  /** @type {ConfirmDialog} */
  let googleMapDialog;
  let googleMapUrl;
  /** @type {string} */
  let googleMapAddress;

  /** ヘルプダイアログ @type {ConfirmDialog} */
  let helpDialog;

  /** @type {boolean} 1回目のスタックかどうか */
  let isFirstStack = true;

  /** @type {boolean} スタック内荷物一覧の削除ボタンが活性かどうか */
  let isActiveDeleteButton = false;

  (() => {
    // 現在地の取得
    geolocator.requestCurrentPosition(false);
    currentPositionStoreUnsubscriber = currentPositionStore.subscribe(
      (position) => {
        if (position) {
          currentPosition = position?.coords;
        }
      },
    );
  })();

  onMount(
    loadingProgress.wrapAsync(async () => {
      await qrCodeScanner.startScanning();

      const shippingList = userContext.deliveryList;
      if (shippingList) {
        for (let i = 0; i < shippingList.length; i++) {
          currentShippingSet.add(shippingList[i].trackingNumber);

          if (shippingList[i].statusText === "済") {
            deliveredPackageList.push(shippingList[i]);
          }
        }
      }
    }),
  );

  // 60秒ごとに現在地を取得
  onMount(() => {
    const interval = setInterval(() => {
      geolocator.requestCurrentPosition(false);
    }, 60 * 1000);
    return () => clearInterval(interval);
  });

  onDestroy(() => {
    currentPositionStoreUnsubscriber?.();

    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();

    // スキャン失敗メッセージのフラグはOFF
    isCountingAfterFailedScan = false;
  });

  async function updateStatuses() {
    await loadingProgress.wrapAsync(applyUpdateToShippingList)();
  }

  /** ステータス更新APIを実行し、ローカルストレージの配達リストを更新する */
  async function applyUpdateToShippingList() {
    try {
      /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
      let successList = [];

      // 現在時刻を保持
      const requestedTimeStamp = Date.now();
      // ステータス更新API実行
      const data = await execStatusUpdateApi(requestedTimeStamp);
      appContext.deliveryListSortType = sortRadio;
      appContext.store();
      await qrCodeScanner.stopScanning();
      unstackedScanDataNum = 0;
      decodedTextList.clear();

      if (data.updateFailed) {
        const updateFailedNumbers = data.updateFailed;
        const outputTrackingNumbers = updateFailedNumbers
          .map((t) => formatTrackingNumber(t))
          .join(", ");
        toast.error(
          $_("errors.unableOutForDelivery", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.ERROR,
          $_("errors.unableOutForDelivery", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
      }
      if (!data.success) {
        // 持ち出しに成功したデータがない場合は中断
        pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
        return;
      }
      successList = data.success;

      // ローカルストレージからリスト取得
      let shippingList = userContext.deliveryList ?? [];

      // ローカルストレージの配達リストから、配達完了済みだが再持ち出しを行う荷物の情報を削除する
      // (持ち出した情報としてsuccessListに入っているので、重複防止のため)
      for (let i = 0; i < trackingListStack.length; i++) {
        if (!trackingListStack[i].isInDeliveredList) {
          // 一度配達完了済みになった荷物のリストに入っていない場合はスキップ
          continue;
        }
        const index = shippingList.findIndex(
          (e) =>
            formatTrackingNumber(e.trackingNumber) ===
            trackingListStack[i].trackingNumber,
        );
        if (index !== -1) {
          shippingList.splice(index, 1);
          shippingList = shippingList;
        }
      }

      // 非同期で各荷物を初期化（緯度経度算出など）
      await Promise.all([
        // DeliveryPackage用の初期化
        ...successList.map((e) => deliveryListUtils.initDeliveryPackage(e)),
      ]);

      // BEから返却された持ち出し完了荷物をスキャン順に並び替え
      /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
      let sortedRegisteredPackages = [];
      for (const scannedPackage of trackingListStack) {
        const registeredPackage = successList.find(
          (e) => e.trackingNumber === scannedPackage.trackingNumber,
        );
        if (registeredPackage) {
          sortedRegisteredPackages.push(registeredPackage);
        }
      }
      for (const registeredPackage of successList) {
        if (!sortedRegisteredPackages.includes(registeredPackage)) {
          sortedRegisteredPackages.push(registeredPackage);
        }
      }
      successList = sortedRegisteredPackages;

      console.log(
        "持出しリスト:",
        "req=" + JSON.stringify(trackingListStack.map((e) => e.trackingNumber)),
        "res=" + JSON.stringify(data.success.map((e) => e.trackingNumber)),
        "merged=" +
          JSON.stringify(sortedRegisteredPackages.map((e) => e.trackingNumber)),
      );

      // 配達リストを指定の方法でソートして反映
      await sortDeliveryList(successList);

      // スキャンした荷物に持ち出した配送センターのIDを設定
      successList.forEach((e) => {
        e.actualRelayLocationId = Number(centerId);
      });

      // スキャンした荷物に番号を付与
      shippingList.push(...successList);
      await deliveryListUtils.numberingAllPackages(
        shippingList,
        userContext.syncFailureList ?? [],
      );

      // ローカルストレージの配達リストに反映
      userContext.deliveryList = shippingList;
      userContext.store();

      const undeliveredList = shippingList.filter(
        (item) => item.statusText === "未",
      );

      if (deliveryListUtils.existsPackageDesiredDateAndTime(undeliveredList)) {
        // 未配達リスト内に配達指定日時または再配達の希望日時が設定された荷物が含まれている場合
        toast.info(
          $_("message.updateCompleteOutForDeliveryExistsDesiredDateTime"),
          { duration: 10000 },
        );
      } else {
        // 未配達リスト内に配達指定日時または再配達の希望日時が設定された荷物が含まれていない場合
        toast.info($_("message.updateCompleteOutForDelivery"));
      }

      // ドライバーの稼働状況をシステムに同期
      reserveUpdateDeliveryRecordsAndSyncBackend(
        userContext,
        requestedTimeStamp,
        DeliveryRecordTypes.OUT_FOR_DELIVERY,
      );

      needToGettingKnowledge.set(true);
      pageRouter.moveToList();
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
        logout();
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
        logout();
      } else {
        // オフライン状態やサーバーエラー応答等が発生した場合
        logger.error(
          "[OutForDeliveryWithQrCodeScan] 持出登録でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
            locationId: centerId,
            trackingListStack: (trackingListStack ?? []).map((e) => ({
              id: e.id,
              trackingNumber: e.trackingNumber,
              updateTime: e.updateTime,
              isManuallyInputted: e.isManuallyInputted,
              addressNotFound: e.addressNotFound,
            })),
          },
          error,
        );
        // 閉塞フラグを確認し、オフラインモード切替えが可能かを判定
        if (import.meta.env.VITE_DISABLED_OFFLINE_MODE_DELIVERED !== "true") {
          // オフラインモード切替えが可能な場合、オフラインモード切替えヘルプを表示
          if (error instanceof OfflineException) {
            toast.recommendOfflineMode($_("errors.offline"));
          } else {
            toast.recommendOfflineMode($_("errors.defaultMessage"));
          }
        } else {
          // オフラインモード切替えが不可の場合、エラーメッセージのみ表示
          if (error instanceof OfflineException) {
            toast.error($_("errors.offline"));
          } else {
            toast.error($_("errors.defaultMessage"));
          }
        }
      }
    }
  }

  /**
   * ステータス更新APIを実行
   * @param {number} requestedTimeStamp リクエスト時刻
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentStatusResponse>}
   */
  async function execStatusUpdateApi(requestedTimeStamp) {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];
    let updateStatus;
    updateStatus = STATUS_OUT_FOR_DELIVERY;

    for (let i = 0; i < trackingListStack.length; i++) {
      let event = {
        trackingNumber: trackingListStack[i].trackingNumber.split("-").join(""),
        status: updateStatus,
        locationId: centerId !== null ? new Number(centerId) : null,
        updateTime: trackingListStack[i].updateTime,
        dateLabelType: null,
      };
      if (trackingListStack[i].isManuallyInputted) {
        event.isManuallyInputted = trackingListStack[i].isManuallyInputted;
      }
      if (trackingListStack[i].needToCancelReturn) {
        event.initializeFields = ["returnStatus", "returnReason"];
      }
      events.push(event);
    }
    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body, requestedTimeStamp);
  }

  /**
   * @param {string} decodedText
   * @param {boolean} needsDecode
   * @param {import("~/libs/qrCodeScanner").CodeType} codeType
   */
  async function onScanSuccess(decodedText, needsDecode, codeType) {
    let isManuallyInputted = false;
    const currentDecodedTextListSize = decodedTextList.size;

    /** @type {Error} */
    let parseError;
    try {
      if (needsDecode) {
        decodedText =
          codeType === CodeType.QRCODE
            ? parseQRCodeEmbeddedTrackingNumber(decodedText)
            : parseCodabarEmbeddedTrackingNumber(decodedText);
      } else {
        isManuallyInputted = true;
      }
    } catch (error) {
      parseError = error;
    } finally {
      decodedTextList.add(decodedText);
    }

    if (decodedTextList.size === currentDecodedTextListSize) {
      // スキャン済みのデータは無視
      return;
    } else if (parseError) {
      if (parseError instanceof TypeError && !isCountingAfterFailedScan) {
        // 形式エラーの場合、10秒後にスキャン成功していなければメッセージを表示する
        isCountingAfterFailedScan = true;
        setTimeout(() => {
          if (isCountingAfterFailedScan) {
            toast.error($_("errors.repeatedScanFailures"));
            isCountingAfterFailedScan = false;
          }
        }, 10000);
      }
      toast.error(parseError.message);
      return;
    }

    // スキャン成功しているためスキャン失敗メッセージのフラグはOFF
    isCountingAfterFailedScan = false;

    // 持出し登録：配達完了以外の配達リストに登録済みの場合はエラー
    if (currentShippingSet.has(decodedText)) {
      const index = deliveredPackageList.findIndex(
        (e) => e.trackingNumber === decodedText,
      );
      if (index === -1) {
        // 配達完了リストにはない荷物の場合
        toast.error($_("errors.shippingListRegistered"), {
          popsWhenPageMoved: true,
        });
        return;
      } else {
        // 配達完了リストにある荷物の場合
        console.log("配達完了登録済みの荷物をスキャン");
        deliveredPackageTrackingNumber = decodedText;
      }
    }

    numberOfScanInProgress++;
    try {
      const now = new Date(
        Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000,
      );
      let year = String(now.getFullYear());
      let month = toDoubleDigits(now.getMonth() + 1);
      let date = toDoubleDigits(now.getDate());
      let hour = toDoubleDigits(now.getHours());
      let min = toDoubleDigits(now.getMinutes());
      let sec = toDoubleDigits(now.getSeconds());
      let updateTime =
        year + "-" + month + "-" + date + " " + hour + ":" + min + ":" + sec;
      let trackingData = {
        id: unstackedScanDataNum + 1,
        trackingNumber: formatTrackingNumber(decodedText), // FIXME: trackingNumberフィールドを異例的にハイフン付きにしており危険
        numberOfPackages: 1,
        address: "取得中...",
        updateTime: updateTime,
        isManuallyInputted: isManuallyInputted,
        needToCancelReturn: null,
        dateLabelType: null,
        afterTommorowChecked: true,
        multipleBoxesChecked: true,
        bringOutReturnRequestPackage: false,
        returnChecked: true,
        isInDeliveredList: false,
        inDeliveredListChecked: true,
        possibleIncorrectAddress: false,
        isDeleteFlag: false,
      };
      trackingList.push(trackingData);
      trackingList = trackingList;
      unstackedScanDataNum++;
      beep();

      await execQrScanApi(decodedText);
    } finally {
      numberOfScanInProgress--;
    }
  }

  function toDoubleDigits(num) {
    if (num < 10) {
      return "0" + String(num);
    } else {
      return String(num);
    }
  }

  /**
   * @param {string} trackingNumber
   */
  async function execQrScanApi(trackingNumber) {
    try {
      const qrScanResponse = await backendApi.getShipmentInfoByQrScan(
        trackingNumber,
        QrScanModes.OUT_FOR_DELIVERY,
      );
      await onReceiveAddress(trackingNumber, {
        qrScanResponse: qrScanResponse,
      });
      checkDeadlineAndDesiredDate(trackingNumber, {
        qrScanResponse: qrScanResponse,
      });
    } catch (error) {
      logger.error(
        "[OutForDeliveryWithQrCodeScan] QRコードスキャンAPIの呼出・データ統合処理でエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          locationId: centerId,
          trackingNumber: trackingNumber,
        },
        error,
      );
      await onReceiveAddress(trackingNumber, { error });
    }
  }

  /**
   * @param {string} trackingNumber
   * @param {{
   *   qrScanResponse?: import("~/libs/backendApi").QrScanResponse,
   *   error?: Error,
   * }} result
   */
  async function onReceiveAddress(trackingNumber, { qrScanResponse, error }) {
    let targetTrackingNumber = formatTrackingNumber(trackingNumber);
    let errorStatus;
    if (error instanceof HTTPError) {
      errorStatus = error.response.status;
    }
    for (let i = 0; i < trackingList.length; i++) {
      const trackingData = trackingList[i];
      if (!trackingData) {
        // 「次へ」ボタンが押下されてtrackingListがクリアされた場合は処理を中断
        return;
      }

      if (trackingData.trackingNumber == targetTrackingNumber) {
        if (!errorStatus && !error) {
          if (Number.isInteger(qrScanResponse.returnStatus)) {
            // 返品登録されている場合
            trackingData.returnStatus = qrScanResponse.returnStatus;
            trackingData.returnReason = qrScanResponse.returnReason;
            // 返品解除対象フラグを設定
            trackingData.needToCancelReturn = true;
            trackingData.returnChecked = false;
            allChecked = false;
          }
          if (qrScanResponse.status == STATUS_DELIVERED) {
            // 配達完了済みの場合
            toast.error(
              $_("errors.unableOutForDelivery", {
                values: { trackingNumber: targetTrackingNumber },
              }),
              { popsWhenPageMoved: true },
            );
            trackingList.splice(i, 1);
            trackingList = trackingList;
            unstackedScanDataNum--;
            return;
          } else if (qrScanResponse.status === STATUS_OUT_FOR_DELIVERY) {
            // 他のドライバーが持ち出し中の場合
            toast.info($_("message.scanOutForDeliveryPackage"));
          }

          if (trackingNumber === deliveredPackageTrackingNumber) {
            // ステータスが「配達完了」ではないが配達完了リストに入っている場合（自端末で配達完了後、Adminでステータスを戻された場合）
            trackingData.isInDeliveredList = true;
            trackingData.inDeliveredListChecked = false;
            allChecked = false;
          }

          // エラー応答でない場合は取得結果を画面反映
          trackingData.address = qrScanResponse.address;
          trackingData.correctedAddress =
            qrScanResponse.correctedReceiverAddress;
          trackingData.receiverName = qrScanResponse.receiverName;
          trackingData.desiredDate = qrScanResponse.desiredDate;
          trackingData.desiredTime = qrScanResponse.desiredTime;
          trackingData.cashOnDeliveryAmount =
            qrScanResponse.cashOnDeliveryAmount;
          trackingData.packageDropPlace = qrScanResponse.packageDropPlace;
          trackingData.extraEvent = qrScanResponse.extraEvent;
          trackingData.numberOfDeliveryAttempts =
            qrScanResponse.numberOfDeliveryAttempts;
          trackingData.redeliveryContext = qrScanResponse.redeliveryContext;
          trackingData.specifiedPickupDatetime =
            qrScanResponse.specifiedPickupDatetime;
          trackingData.numberOfPackages = qrScanResponse.numberOfPackages;
          if (qrScanResponse.numberOfPackages > 1) {
            trackingData.multipleBoxesChecked = false;
            allChecked = false;
          }

          // お届け先住所の緯度経度を取得
          let latlon;
          try {
            latlon = await parseAddress(qrScanResponse.address);
          } catch (error) {
            console.error(error);
            trackingData.addressNotFound = true;
          }

          // 現在地情報が取得できている場合は方位と距離を計算
          if (currentPosition && latlon) {
            try {
              // 現在地→お届け先住所の方位と距離を計算
              const { label: cardinalDirection } = calcBearing(
                currentPosition.latitude,
                currentPosition.longitude,
                latlon.latitude,
                latlon.longitude,
              );
              const distance = Math.round(
                calcDistance(
                  currentPosition.latitude,
                  currentPosition.longitude,
                  latlon.latitude,
                  latlon.longitude,
                ),
              );

              trackingData.directionAndDistance = {
                direction: cardinalDirection,
                distance: formatDistanceInMeters(distance),
              };
            } catch (error) {
              console.error(error);
              trackingData.addressNotFound = true;
            }
          }

          // 住所誤り判定。レベル3(町丁目まで判別)以下は住所誤りと判定
          // parseAddressでエラーが出たときも住所誤りになる
          trackingData.possibleIncorrectAddress =
            ((latlon && latlon.level) || 0) <= 3;

          // 配達リストの表示を更新
          trackingList = trackingList;

          if (!googleMapCacheUrl) {
            googleMapCacheUrl = GOOGLE_MAP_BASE_URL.replace(
              "<address>",
              encodeURIComponent(trackingData.address),
            );
          }
        } else if (errorStatus == 400) {
          // FIXME: 連番が欠番になる
          toast.error(
            $_("errors.ineligibleTrackingNumberOfOutForDelivery", {
              values: { trackingNumber: targetTrackingNumber },
            }),
            { initial: 0, popsWhenPageMoved: true },
          );
          notificationHistoryUtils.deleteAndAddHistory(
            userContext.loginUser.username,
            NotificationCategory.ERROR,
            $_("errors.ineligibleTrackingNumberOfOutForDelivery", {
              values: { trackingNumber: targetTrackingNumber },
            }),
          );
          trackingList.splice(i, 1);
          trackingList = trackingList;
          unstackedScanDataNum--;
        } else if (errorStatus == 401) {
          toast.error($_("errors.unauthorized"));
          logout();
        } else if (errorStatus == 403) {
          toast.error($_("errors.forbidden"));
          logout();
        } else {
          toast.error(
            $_("errors.getPackageInfoFailed", {
              values: { trackingNumber: targetTrackingNumber },
            }),
            { popsWhenPageMoved: true },
          );
          trackingData.address = "取得失敗";
          trackingData.addressNotFound = true;
          trackingList = trackingList;
        }
        break;
      }
    }
    onAddResultLine();
  }

  function onScanError(errorMessage, error) {
    console.log(
      "[OutForDeliveryWithQrCodeScan.svelte] onScanError:",
      errorMessage,
      error,
    );
  }

  function logout() {
    pageRouter.moveToLogin();
  }

  /** 戻るボタン押下時の処理 */
  function confirmDiscardAndGoBack() {
    if (trackingList.length != 0 || trackingListStack.length != 0) {
      functionAfterDiscard = goToBackPage;
      backConfirmDialog.openDialog();
    } else {
      goToBackPage();
    }
  }

  function goToBackPage() {
    unstackedScanDataNum = 0;
    decodedTextList.clear();
    pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
  }

  /**
   * フッタボタン押下時の処理
   * @param {() => void} pageTransition
   */
  function confirmDiscardAndChangePage(pageTransition) {
    if (trackingList.length != 0 || trackingListStack.length != 0) {
      functionAfterDiscard = pageTransition;
      backConfirmDialog.openDialog();
    } else {
      pageTransition();
    }
  }

  // eslint-disable-next-line no-unused-vars
  function onAddResultLine(resultLineElement) {
    (async () => {
      await tick();
      document.getElementById("resultAreaBottomAnchor")?.scrollIntoView();
    })();
  }

  /**
   * @param {HTMLElement} node
   * @returns {import("svelte/action").ActionReturn}
   */
  function makeResultLineSwipeable(node) {
    const moveXThreshould = -80;
    let allowRemove = false;

    /**
     * @param {number} removeIndex
     * @param {TrackingData} trackingData
     */
    const pushToKitchenSnackbar = (removeIndex, trackingData) => {
      kitchenSnackbar.push({
        props: {
          timeoutMs: 4000,
        },
        label: `削除しました (${trackingData.trackingNumber})`,
        actions: [
          {
            text: "取り消す",
            onClick: () => {
              if (
                !trackingList.find(
                  (e) => e.trackingNumber === trackingData.trackingNumber,
                )
              ) {
                decodedTextList.add(
                  trackingData.trackingNumber.replaceAll("-", ""),
                );
                trackingList.splice(removeIndex, 0, trackingData);
                trackingList = trackingList;
                unstackedScanDataNum++;
                // 本日指定、指定日時超過のカウントを戻す
                if (deletedDateLabelType === 0) {
                  numberOfOverduePackages++;
                } else if (deletedDateLabelType === 1) {
                  numberOfTodayPackages++;
                }
              }
            },
          },
        ],
      });
    };

    return swipeable(node, {
      allowLeftSwipe: true,
      scrollableParent: {
        element: node.parentElement?.parentElement,
        overflowY: "auto",
      },

      onPanMove: (touchMoveX) => {
        if (touchMoveX <= moveXThreshould) {
          node.parentElement.style.backgroundColor = "#ff0000";
          allowRemove = true;
        } else {
          node.parentElement.style.backgroundColor = "#ff8080";
          allowRemove = false;
        }
      },

      onPanEnd: (touchMoveX) => {
        if (allowRemove && touchMoveX <= moveXThreshould) {
          deletedDateLabelType = null;
          const trackingNumber = node
            .querySelector(".tracking")
            ?.textContent.slice(0, 14);
          if (trackingNumber) {
            console.log(`持ち出し対象リストから削除: ${trackingNumber}`);
            setTimeout(() => {
              const removeIndex = trackingList.findIndex(
                (e) => e.trackingNumber === trackingNumber,
              );
              if (removeIndex != -1) {
                // 本日指定、指定日時超過のカウントを減らす
                if (trackingList[removeIndex].dateLabelType === 0) {
                  deletedDateLabelType = 0;
                  numberOfOverduePackages--;
                } else if (trackingList[removeIndex].dateLabelType === 1) {
                  deletedDateLabelType = 1;
                  numberOfTodayPackages--;
                }
                const removeTrackingData = trackingList.splice(
                  removeIndex,
                  1,
                )[0];
                trackingList = trackingList;
                unstackedScanDataNum--;
                pushToKitchenSnackbar(removeIndex, removeTrackingData);
                decodedTextList.delete(
                  removeTrackingData.trackingNumber.replaceAll("-", ""),
                );
              }
              checkAllCheckpoints();
            }, 300);
            return -window.outerWidth;
          }
        }
        return 0;
      },
    });
  }

  /**
   * スタックに荷物情報を追加する
   */
  function pushPackagesToStack() {
    isScanningMode = false;
    trackingListStack = trackingListStack.concat(trackingList);
    stackedScanDataNum += trackingList.length;
    unstackedScanDataNum = 0;
    trackingList = [];
    qrCodeScanner.pauseOrResumeScanning(true);
  }

  /**
   * スタック内荷物をダイアログで表示する
   */
  function openStackItemsDialog() {
    // 削除用のチェックボックス、ボタンの状態を初期化
    trackingListStack.forEach((e) => {
      e.isDeleteFlag = false;
    });
    isActiveDeleteButton = false;

    qrCodeScanner.pauseOrResumeScanning(true);
    stackDialog.openDialog();
  }

  /**
   * スタックに荷物を追加した後にスキャンを再開する
   */
  function restartScan() {
    isScanningMode = true;
    isFirstStack = false;
    qrCodeScanner.pauseOrResumeScanning(false);
  }

  /**
   * 既存の配達リストに残件がないか持ち出す荷物が2件以上ある場合、並び替え確認ダイアログを表示する。
   * 指定日時超過もしくは本日指定の荷物がある場合、期限確認ダイアログを表示する。
   * それ以外の場合、ステータス更新APIを実行する。
   */
  function openDialogOrUpdateStatuses() {
    let registeredList = userContext.deliveryList ?? [];
    let undeliveredList = registeredList.filter(
      (item) => item.statusText !== "済",
    );
    if (undeliveredList.length > 0 || trackingListStack.length == 1) {
      if (numberOfOverduePackages || numberOfTodayPackages) {
        showDeadlineAndDesiredDateDialog = true;
      } else {
        sortRadio = 1;
        updateStatuses();
      }
    } else {
      showSortAndRegisterDialog = true;
    }
  }

  /**
   * 指定日時超過もしくは本日指定の荷物がある場合、期限確認ダイアログを表示する。
   * それ以外の場合、ステータス更新APIを実行する。
   */
  function openDialogOrUpdateStatusesAfterSort() {
    closeSortAndRegisterDialog();
    if (numberOfOverduePackages || numberOfTodayPackages) {
      showDeadlineAndDesiredDateDialog = true;
    } else {
      updateStatuses();
    }
  }

  /**
   * 並び替え確認ダイアログを非表示にする
   */
  function closeSortAndRegisterDialog() {
    showSortAndRegisterDialog = false;
  }

  /**
   * 配送リストをソートする
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} successList
   */
  async function sortDeliveryList(successList) {
    console.log(`配達リストのソート：区分=${sortRadio}`);
    if (sortRadio === 0) {
      if ($currentPositionStore) {
        // 総移動距離が短い順でソート
        await deliveryListUtils.sortWithTSP(successList, $currentPositionStore);
      } else {
        toast.error($_("errors.cantSortWithfailedToGetLocation"));
      }
    } else if (sortRadio === 1) {
      // 持出し登録の昇順でソート（既に昇順でソート済のため何もしない）
    } else {
      // 持出し登録の降順でソート（既に昇順でソート済のため反転させる）
      successList.reverse();
    }
  }

  /**
   * 荷物の(再)配達日時によってラベル付けと個数カウントを行う
   * @param {string} trackingNumber
   * @param {object} root0
   * @param {import("~/libs/backendApi").QrScanResponse} root0.qrScanResponse
   */
  function checkDeadlineAndDesiredDate(trackingNumber, { qrScanResponse }) {
    /**@type {number | null} 指定された(再)配達日時の種別 */
    /** 0: 指定日時超過、1: 本日指定、2: 明日以降指定、null: それ以外 */
    let dateLabelType = null;

    /** @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>} 指定された(再)配達日時 */
    const calculatedDateAndTime = desiredDateTime.resolve(qrScanResponse);

    // (再)配達日時が本日指定または指定日時超過に含まれるかどうかを確認する
    if (calculatedDateAndTime.length === 1 && calculatedDateAndTime[0].date) {
      // (再)配達日時が1つ指定されている場合

      // 指定の日付と現在の日付を比較
      // 日付だけを比較するため、時間をデフォルトの9時に設定
      const date = new Date(calculatedDateAndTime[0].date);
      date.setHours(9, 0, 0, 0);
      const compareAscResult = compareAsc(today, date);

      if (compareAscResult === 0) {
        // 日付が等しい場合は指定時間帯の終了時刻で判定
        // 指定時間帯の終了時刻を取得
        let endHour = null;
        if (!calculatedDateAndTime[0].timeFrame) {
          // 時間帯が指定されていない場合は24時(=次の日の0時）を設定
          endHour = parseInt("24");
        } else if (
          calculatedDateAndTime[0].timeFrame.substring(2, 4) === "00"
        ) {
          endHour = parseInt("24");
        } else {
          endHour = parseInt(
            calculatedDateAndTime[0].timeFrame.substring(2, 4),
          );
        }

        // 指定時間帯の終了時刻と現在時刻を比較
        if (now.getHours() >= endHour) {
          dateLabelType = 0;
          numberOfOverduePackages++;
        } else {
          dateLabelType = 1;
          numberOfTodayPackages++;
        }
      } else if (compareAscResult === 1) {
        // 指定日付が過去の場合は指定日時超過としてカウント
        dateLabelType = 0;
        numberOfOverduePackages++;
      } else {
        // 指定日付が未来の場合は明日以降指定のラベルを表示
        dateLabelType = 2;
      }
    } else if (calculatedDateAndTime.length > 1) {
      // 複数の希望時間帯が指定されている場合

      // 現在の日時より未来の日時をフィルタリング
      const futureDateAndTime = calculatedDateAndTime
        .map((dateObj) => createDateFromParts(dateObj.date, dateObj.timeFrame))
        .filter((date) => isFuture(date));

      if (futureDateAndTime.length === 0) {
        // 未来の日時が存在しない場合はラベルを表示しない
        dateLabelType = null;
      } else {
        // 未来の日時が存在する場合は、現在の日時に最も近い未来の日時を取得
        const nearestDateAndTime = futureDateAndTime.reduce(
          (closest, current) => {
            if (
              !closest ||
              differenceInMilliseconds(current, now) <
                differenceInMilliseconds(closest, now)
            ) {
              return current;
            } else {
              return closest;
            }
          },
          null,
        );

        // 取得した現在の日時に最も近い未来の日時が、本日か明日以降かを判定
        const specifiedDateToday = isToday(nearestDateAndTime);
        specifiedDateToday
          ? ((dateLabelType = 1), numberOfTodayPackages++)
          : (dateLabelType = 2);
      }
    } else {
      // (再)配達日時が指定されていない場合、時間帯のみ指定されている場合は、スキップ
      dateLabelType = null;
    }

    // 画面に反映
    let targetTrackingNumber = formatTrackingNumber(trackingNumber);
    for (let i = 0; i < trackingList.length; i++) {
      if (trackingList[i].trackingNumber == targetTrackingNumber) {
        trackingList[i].dateLabelType = dateLabelType;
        if (dateLabelType === 1) {
          // 本日指定のラベルに表示する指定時間をフォーマット
          trackingList[i].timeFrame = formattedDesiredTime(
            calculatedDateAndTime,
          );
        } else if (dateLabelType === 2) {
          trackingList[i].afterTommorowChecked = false;
          allChecked = false;
        }
      }
    }
  }

  /**
   * 日付と時間を結合してDateオブジェクトを生成する
   * 時間帯を渡すと、終了時刻のDateオブジェクトを生成する
   * 時間帯が指定されていない場合は、24時(=次の日の0時）を設定する
   * @param {string} date 日付
   * @param {string} timeFrame 時間帯
   * @returns {Date} 終了時間のDateオブジェクト
   */
  function createDateFromParts(date, timeFrame) {
    if (!timeFrame && timeFrame === "0000") {
      return null;
    } else {
      const [year, month, day] = date.split("-").map(Number);
      const [hour, minute] =
        timeFrame.slice(2, 4) === "00"
          ? [23, 59]
          : [timeFrame.slice(2, 4), 0].map(Number);
      return new Date(year, month - 1, day, hour, minute);
    }
  }

  /**
   * 本日指定のラベルに表示する指定時間をフォーマットする
   * @param {Array<import("~/libs/commonTypes").DateAndTimeFrame>} calculatedDateAndTime 指定された(再)配達日時
   * @returns {string}
   */
  function formattedDesiredTime(calculatedDateAndTime) {
    let timeFrame;
    if (calculatedDateAndTime.length === 1) {
      timeFrame = calculatedDateAndTime[0].timeFrame
        ? calculatedDateAndTime[0].timeFrame
        : "0000";
    } else if (calculatedDateAndTime.length > 1) {
      // 複数時間帯が指定されている場合、今日の日付の指定時間帯を改めて取得
      const todayDateAndTime = calculatedDateAndTime.filter((dateObj) =>
        isToday(createDateFromParts(dateObj.date, dateObj.timeFrame)),
      );
      timeFrame = todayDateAndTime[0].timeFrame;
    }
    let startTime = timeFrame.substring(0, 2);
    let endTime = timeFrame.substring(2, 4);
    console.log(`startTime: ${startTime}, endTime: ${endTime}`);
    if (startTime.substring(0, 1) === "0") {
      // 1文字目が0の場合は0を省略する
      startTime = timeFrame.substring(1, 2);
    }
    if (endTime.substring(0, 1) === "0") {
      // 1文字目が0の場合は0を省略する
      endTime = timeFrame.substring(3, 4);
    }

    if (timeFrame === "0000") {
      return ``;
    } else if (endTime === "0") {
      // endTimeが0の場合は表示を省略する
      return `(${startTime}時~)`;
    } else {
      return `(${startTime}~${endTime}時)`;
    }
  }

  /**
   * 期限確認ダイアログを非表示にする
   */
  function closeDeadlineAndDesiredDateDialog() {
    showDeadlineAndDesiredDateDialog = false;
  }

  /**
   * 各荷物の確認事項（翌日以降指定、返品対象、一度配達済み、複数個口）が全て確認済みかどうかをチェックする
   */
  function checkAllCheckpoints() {
    allChecked = true;
    for (let i = 0; i < trackingList.length; i++) {
      if (
        !trackingList[i].afterTommorowChecked ||
        !trackingList[i].multipleBoxesChecked ||
        !trackingList[i].returnChecked ||
        !trackingList[i].inDeliveredListChecked
      ) {
        allChecked = false;
      }
    }
  }

  /**
   * 住所をクリップボードにコピー
   * @param {string} address
   */
  function copyAddress(address) {
    navigator.clipboard.writeText(address);
  }

  /**
   * Googleマップで住所を開く
   * @param {string} address
   */
  async function openGoogleMap(address) {
    const encodedDestination = encodeURIComponent(
      // qrScanのAPIのレスポンスで郵便番号が返ってこないため、郵便番号はnull
      await addressUtils.normalizeAddressForGoogleMap(address, null),
    );
    // 拡大率を指定しないことで、周辺がいい感じに表示されるのを期待
    googleMapUrl = `https://maps.google.com/maps?output=embed&q=${encodedDestination}&t=m&hl=ja`;
    googleMapAddress = address;
    qrCodeScanner.pauseOrResumeScanning(true);
    googleMapDialog.openDialog();
  }

  /** スタック内荷物一覧の削除チェック状態を確認し、削除ボタンの活性状態を決定する */
  function checkDeleteButtonActive() {
    let checkResult = false;
    trackingListStack.forEach((e) => {
      if (e.isDeleteFlag) checkResult = true;
    });
    isActiveDeleteButton = checkResult;
  }
</script>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">
      <Wrapper rich>
        <span tabindex="0" role="button">持ち出し登録</span>
        <IconButton
          size="button"
          class="material-icons"
          style="vertical-align: sub;">warehouse</IconButton
        >
        {#if centerName}
          <Tooltip xPos="start" unbounded>{centerName}</Tooltip>
        {/if}
      </Wrapper>
    </svelte:fragment>
    <svelte:fragment slot="right">
      <IconButton
        class="material-icons"
        size="button"
        style="font-size: 24px; color: rgba(0, 0, 0, 0.54)"
        on:click={() => {
          qrCodeScanner.pauseOrResumeScanning(true);
          helpDialog.openDialog();
        }}>help_outline</IconButton
      >
    </svelte:fragment>
  </Header>

  <main in:fade>
    <QrCodeScanner
      bind:this={qrCodeScanner}
      onScanSuccessHandler={onScanSuccess}
      onScanErrorHandler={onScanError}
      enableInputForm={true}
    />

    {#if numberOfTodayPackages > 0 || numberOfOverduePackages > 0}
      <div class="countArea">
        {#if numberOfTodayPackages}
          <p class="countLabel">
            本日指定<span class="badge">{numberOfTodayPackages}</span>
          </p>
        {/if}
        {#if numberOfOverduePackages > 0}
          <p class="countLabel">
            指定日時超過<span class="badge">{numberOfOverduePackages}</span>
          </p>
        {/if}
      </div>
    {/if}

    {#if unstackedScanDataNum != 0}
      <div id="resultArea" class="resultArea">
        <div class="accordion-container">
          <Accordion multiple>
            {#each trackingList as data, index (data.trackingNumber)}
              <div class="resultLineWrapper" animate:flip>
                <div
                  class="resultLineWrapper"
                  use:onAddResultLine
                  use:makeResultLineSwipeable
                >
                  <Panel style="z-index: -2;">
                    <div
                      class="resultLine"
                      class:notAllowedToBringOut={Number.isInteger(
                        data.returnStatus,
                      ) &&
                        data.returnStatus === STATUS_REQUESTING_FOR_RETURN &&
                        !data.bringOutReturnRequestPackage}
                    >
                      <div class="resultLineInner">
                        <div class="resultLeftArea">
                          <div class="badgeArea">
                            <span class="notice">{index + 1}</span>
                          </div>
                          <div class="resultLineInfo">
                            <div class="resultLineInfoInner">
                              <p class="tracking">
                                <!-- 複数個口の場合は個数を表示-->
                                {data.trackingNumber}{#if data.numberOfPackages > 1}<span
                                    class="quantity"
                                    >（全{data.numberOfPackages}個）</span
                                  >{/if}
                              </p>
                              {#if data.dateLabelType === 0}
                                <p class="durationLabel">指定日時超過</p>
                              {:else if data.dateLabelType === 1}
                                <p class="durationLabel">
                                  本日指定{data.timeFrame}
                                </p>
                              {/if}
                            </div>
                            <div class="addressArea">
                              <span
                                >{#if data.correctedAddress}
                                  <span class="highlight">
                                    {data.correctedAddress}
                                    <span class="note">※訂正された住所</span>
                                  </span>
                                {:else}
                                  {data.address}
                                  {#if data.possibleIncorrectAddress}
                                    <span class="possibleIncorrectAddress"
                                      >住所誤りの可能性があります。<br
                                      />持ち出す前にお届け先の確認を推奨します。</span
                                    >
                                    <div class="toolButtonArea">
                                      <Wrapper rich>
                                        <button
                                          class="copyAddressButton"
                                          on:click|stopPropagation={() =>
                                            copyAddress(data.address)}
                                        >
                                          <span class="material-icons"
                                            >content_copy</span
                                          >
                                          <span
                                            >住宅地図アプリ用に住所をコピー</span
                                          >
                                        </button>
                                        <Tooltip
                                          style="top: unset; bottom: 26px; transform: translateX(calc(-50% - 42px));"
                                        >
                                          <TooltipContent
                                            >住所をクリップボードにコピーしました</TooltipContent
                                          >
                                        </Tooltip>
                                      </Wrapper>
                                      <Wrapper rich>
                                        <button
                                          class="googleMapButton"
                                          on:click|stopPropagation={() =>
                                            openGoogleMap(data.address)}
                                        >
                                          <span class="material-icons"
                                            >location_on</span
                                          >
                                          <span>GoogleMapで確認</span>
                                        </button>
                                      </Wrapper>
                                    </div>
                                  {/if}
                                {/if}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="resultRightArea">
                          {#if data.directionAndDistance}
                            <p class="directionAndDistance">
                              [{data.directionAndDistance.direction}]<br />{data
                                .directionAndDistance.distance}
                            </p>
                          {/if}
                          <div class="button">
                            <AccordionHeader
                              style="width: fit-content; border-radius: 20px; border: none;"
                            >
                              <IconButton
                                slot="icon"
                                class="toggleDetailsButton"
                                toggle
                                ripple={false}
                              >
                                <Icon class="material-icons" on
                                  >expand_less</Icon
                                >
                                <Icon class="material-icons">expand_more</Icon>
                              </IconButton>
                            </AccordionHeader>
                          </div>
                        </div>
                      </div>
                      {#if Number.isInteger(data.returnStatus) && data.returnStatus === STATUS_REQUESTING_FOR_RETURN && !data.bringOutReturnRequestPackage}
                        <!-- 返品要求中かつ持ち出し確認前の場合 -->
                        <div class="warningText">
                          <div class="returnNoticeArea">
                            この荷物は返品を要求されています。<br />
                            荷物を返品用のかご車に戻し、返品待ちとして登録してください。<br
                            />
                            (返品理由：{$_(
                              `classes.returnReason.${data.returnReason}`,
                            )})
                            <div class="bringOutButtonArea">
                              <Button
                                variant="outlined"
                                style="border-color: #1976d2;"
                                on:click={() => {
                                  data.bringOutReturnRequestPackage = true;
                                }}>持ち出しを継続する</Button
                              >
                              <Button
                                variant="unelevated"
                                on:click={() => {
                                  const i = trackingList.findIndex(
                                    (e) =>
                                      e.trackingNumber === data.trackingNumber,
                                  );
                                  if (i !== -1) {
                                    trackingList.splice(i, 1);
                                    trackingList = trackingList;
                                    unstackedScanDataNum--;
                                  }
                                  checkAllCheckpoints();
                                }}>取消</Button
                              >
                            </div>
                          </div>
                        </div>
                      {:else}
                        <!-- 返品要求中かつ持ち出し確認後、または返品要求中ではない場合 -->
                        {#if data.dateLabelType === 2}
                          <div class="warningText">
                            <FormField>
                              <Checkbox
                                bind:checked={data.afterTommorowChecked}
                                on:change={checkAllCheckpoints}
                              />
                              <span slot="label"
                                >明日以降ご指定の荷物であることを確認したうえで持ち出す</span
                              >
                            </FormField>
                          </div>
                        {/if}
                        {#if Number.isInteger(data.returnStatus)}
                          {#if data.returnStatus === STATUS_REQUESTING_FOR_RETURN}
                            <!-- 返品要求中かつ持ち出し確認後の場合 -->
                            <div class="warningText">
                              <FormField>
                                <Checkbox
                                  bind:checked={data.returnChecked}
                                  on:change={checkAllCheckpoints}
                                />
                                <span slot="label">
                                  返品要求を解除したうえで持ち出す<br />
                                  (返品理由：{$_(
                                    `classes.returnReason.${data.returnReason}`,
                                  )})
                                </span>
                              </FormField>
                            </div>
                          {:else}
                            <!-- 返品対象の場合 -->
                            <div class="warningText">
                              <FormField>
                                <Checkbox
                                  bind:checked={data.returnChecked}
                                  on:change={checkAllCheckpoints}
                                />
                                <span slot="label">
                                  返品対象の荷物であることを確認したうえで持ち出す<br
                                  />
                                  (返品理由：{$_(
                                    `classes.returnReason.${data.returnReason}`,
                                  )})
                                </span>
                              </FormField>
                            </div>
                          {/if}
                        {/if}
                        {#if data.isInDeliveredList}
                          <div class="warningText">
                            <FormField>
                              <Checkbox
                                bind:checked={data.inDeliveredListChecked}
                                on:change={checkAllCheckpoints}
                              />
                              <span slot="label"
                                >一度配達を完了した荷物であることを確認したうえで持ち出す</span
                              >
                            </FormField>
                          </div>
                        {/if}
                        {#if data.numberOfPackages > 1}
                          <div class="warningText">
                            <FormField>
                              <Checkbox
                                bind:checked={data.multipleBoxesChecked}
                                on:change={checkAllCheckpoints}
                              />
                              <span slot="label"
                                >荷物が{data.numberOfPackages}個あることを確認した</span
                              >
                            </FormField>
                          </div>
                        {/if}
                      {/if}
                    </div>
                    <Content style="padding: 0;">
                      <PackageInformation {data} />
                    </Content>
                  </Panel>
                </div>
                <div class="resultLineBackground">
                  <span class="label">削除</span><span class="material-icons"
                    >delete</span
                  >
                </div>
              </div>
            {/each}
          </Accordion>
        </div>
        <div
          id="resultAreaBottomAnchor"
          style="height: 70px; background-color: #fff;"
        ></div>
      </div>
    {:else if !isScanningMode && unstackedScanDataNum === 0}
      <div class="startScanArea">
        <button class="startScanBtn" on:click={restartScan}
          >別の荷物を続けてスキャンする</button
        >
      </div>
    {/if}

    <div class="buttonArea">
      <button class="backBtn" on:click={confirmDiscardAndGoBack}>戻る</button>
      {#if stackedScanDataNum != 0}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div class="badgeNumBtn" on:click={openStackItemsDialog}>
          {stackedScanDataNum}
        </div>
        {#if isFirstStack}
          <div class="stackedPackagesTooltip">
            赤丸の数字をタップすることで、登録待ちの荷物を確認できます。
          </div>
        {/if}
      {/if}
      {#if isScanningMode || unstackedScanDataNum != 0}
        <button
          class={numberOfScanInProgress == 0 &&
          (unstackedScanDataNum || stackedScanDataNum) &&
          allChecked
            ? "confirmBtn"
            : "disabledBtn"}
          disabled={numberOfScanInProgress > 0 ||
            (!unstackedScanDataNum && !stackedScanDataNum) ||
            !allChecked}
          on:click={pushPackagesToStack}>次へ</button
        >
      {:else}
        <button
          class={stackedScanDataNum > 0 ? "confirmBtn" : "disabledBtn"}
          on:click={openDialogOrUpdateStatuses}
          disabled={stackedScanDataNum === 0}>確定</button
        >
      {/if}
    </div>
  </main>

  <Footer {confirmDiscardAndChangePage} />
</div>

<div class="subContentsWrapper">
  <!-- リスト項目削除確認用のSnackbar -->
  <div class="snackbarContainer">
    <Kitchen bind:this={kitchenSnackbar} />
  </div>

  <!-- GoogleMapの事前キャッシュ用の透明なiframe -->
  {#if googleMapCacheUrl}
    <iframe
      title=""
      src={googleMapCacheUrl}
      width="1"
      height="1"
      frameborder="0"
      style="position: absolute; bottom: 0; right: 0; opacity: 0;"
      referrerpolicy="no-referrer"
      use:disposeIframe
    ></iframe>
  {/if}

  <!-- 戻るダイアログ -->
  <ConfirmDialog
    bind:this={backConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        functionAfterDiscard();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content"
      >読み込んだ荷物情報は破棄されます。<br />よろしいですか？</svelte:fragment
    >
  </ConfirmDialog>

  <!-- スタック内荷物一覧 -->
  <div class="stackDialog">
    <ConfirmDialog
      bind:this={stackDialog}
      mandatory={true}
      type={ConfirmDialogTypes.CLOSE}
      onDialogClosedHandler={() => {
        if (isScanningMode) {
          qrCodeScanner.pauseOrResumeScanning(false);
        }
      }}
    >
      <svelte:fragment slot="title"
        >登録待ちの荷物：{stackedScanDataNum}件</svelte:fragment
      >
      <svelte:fragment slot="content">
        <div id="stackArea" class="resultArea">
          <Accordion multiple>
            {#each trackingListStack as data, index}
              <div class="resultLineWrapper">
                <Panel>
                  <div class="resultLine stackLine">
                    <div class="resultLineInner">
                      <div class="resultLeftArea">
                        <div class="badgeArea">
                          <span class="notice">{index + 1}</span>
                        </div>
                        <div class="resultLineInfo">
                          <div class="resultLineInfoInner">
                            <p class="tracking">
                              <!-- 複数個口の場合は個数を表示-->
                              {data.trackingNumber}{#if data.numberOfPackages > 1}<span
                                  class="quantity"
                                  >（全{data.numberOfPackages}個）</span
                                >{/if}
                            </p>
                            {#if data.dateLabelType === 0}
                              <p class="durationLabel">指定日時超過</p>
                            {:else if data.dateLabelType === 1}
                              <p class="durationLabel">本日指定</p>
                            {/if}
                          </div>
                          <div class="addressArea">
                            <span
                              >{#if data.correctedAddress}
                                <span class="highlight">
                                  {data.correctedAddress}
                                  <span class="note">※訂正された住所</span>
                                </span>
                              {:else}
                                {data.address}
                                {#if data.possibleIncorrectAddress}
                                  <span class="possibleIncorrectAddress"
                                    >住所誤りの可能性があります。<br
                                    />持ち出す前にお届け先の確認を推奨します。</span
                                  >
                                  <div class="toolButtonArea">
                                    <Wrapper rich>
                                      <button
                                        class="copyAddressButton"
                                        on:click|stopPropagation={() =>
                                          copyAddress(data.address)}
                                      >
                                        <span class="material-icons"
                                          >content_copy</span
                                        >
                                        <span
                                          >住宅地図アプリ用に住所をコピー</span
                                        >
                                      </button>
                                      <Tooltip
                                        style="top: unset; bottom: 26px; transform: translateX(calc(-50% - 42px));"
                                      >
                                        <TooltipContent
                                          >住所をクリップボードにコピーしました</TooltipContent
                                        >
                                      </Tooltip>
                                    </Wrapper>
                                    <Wrapper rich>
                                      <button
                                        class="googleMapButton"
                                        on:click|stopPropagation={() =>
                                          openGoogleMap(data.address)}
                                      >
                                        <span class="material-icons"
                                          >location_on</span
                                        >
                                        <span>GoogleMapで確認</span>
                                      </button>
                                    </Wrapper>
                                  </div>
                                {/if}
                              {/if}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="resultRightArea">
                      <FormField>
                        <Checkbox
                          bind:checked={data.isDeleteFlag}
                          on:change={checkDeleteButtonActive}
                        />
                      </FormField>
                      <div class="button">
                        <AccordionHeader
                          style="width: fit-content; border-radius: 20px; border: none;"
                        >
                          <IconButton
                            slot="icon"
                            class="toggleDetailsButton"
                            toggle
                            ripple={false}
                          >
                            <Icon class="material-icons" on>expand_less</Icon>
                            <Icon class="material-icons">expand_more</Icon>
                          </IconButton>
                        </AccordionHeader>
                      </div>
                    </div>
                  </div>
                  <Content style="padding: 0;">
                    <PackageInformation {data} /></Content
                  >
                </Panel>
              </div>
            {/each}
          </Accordion>
        </div>
        {#if stackedScanDataNum > 0}
          <div
            class="deleteButtonArea"
            style="margin-top: 10px; text-align: center;"
          >
            <Button
              color="secondary"
              variant="unelevated"
              style="margin-top: 10px;"
              disabled={!isActiveDeleteButton}
              on:click={() => {
                trackingListStack.forEach((data) => {
                  if (data.isDeleteFlag) {
                    console.log(
                      `スタックされた持ち出し対象リストから削除: ${data.trackingNumber}`,
                    );
                    // 本日指定、指定日時超過のカウントを減らす
                    if (data.dateLabelType === 0) {
                      numberOfOverduePackages--;
                    } else if (data.dateLabelType === 1) {
                      numberOfTodayPackages--;
                    }
                    stackedScanDataNum--;
                    decodedTextList.delete(
                      data.trackingNumber.replaceAll("-", ""),
                    );
                  }
                });
                trackingListStack = trackingListStack.filter(
                  (data) => !data.isDeleteFlag,
                );
                isActiveDeleteButton = false;
              }}
            >
              <Label>削除する</Label>
            </Button>
          </div>
        {/if}
      </svelte:fragment>
    </ConfirmDialog>
  </div>

  <!-- 並び替え確認ダイアログ -->
  {#if showSortAndRegisterDialog}
    <div id="sortModal" class="container">
      <div class="modal">
        <div class="modalTitle">
          <div class="modalTitleInner">
            <span class="material-icons md-dark" style="font-size: 30px;"
              >warning_amber</span
            >
          </div>
          <p>確認</p>
        </div>
        <p class="sortModalP">
          {stackedScanDataNum}件の荷物が持ち出し登録されます。
        </p>
        <p class="sortModalP">
          デフォルトでは総移動距離が短い順に配達するルートが登録されます。<br />
          変更したい場合は、以下から希望ルートを選択してください。
        </p>
        <div class="sortArea">
          <label for="sortRadio0"
            ><input
              type="radio"
              name="sortRadio"
              id="sortRadio0"
              bind:group={sortRadio}
              value={0}
            />総移動距離が短い順に配達<span class="recommend">推奨</span></label
          >
          <label for="sortRadio1"
            ><input
              type="radio"
              name="sortRadio"
              id="sortRadio1"
              bind:group={sortRadio}
              value={1}
            />スキャンした順に配達</label
          >
          <label for="sortRadio2"
            ><input
              type="radio"
              name="sortRadio"
              id="sortRadio2"
              bind:group={sortRadio}
              value={2}
            />スキャンした逆順に配達</label
          >
        </div>
        <p class="sortModalSubP">※登録したリストは手動で並び替えが可能です。</p>
        <div class="modalButtonArea">
          <Button
            style="color: #fff; background-color: #018786; width: 100px;"
            on:click={openDialogOrUpdateStatusesAfterSort}>OK</Button
          >
        </div>
        <button class="modalCloseButton" on:click={closeSortAndRegisterDialog}
          ><span class="material-icons md-18"> close </span></button
        >
      </div>
    </div>
    <div class="modalBackground" />
  {/if}

  <!-- 期限確認ダイアログ -->
  {#if showDeadlineAndDesiredDateDialog}
    <div id="deadlineModal" class="container">
      <div class="modal">
        <div class="modalTitle">
          <div class="modalTitleInner">
            <span class="material-icons md-dark" style="font-size: 30px;"
              >warning_amber</span
            >
          </div>
          <p>確認</p>
        </div>
        <p class="deadlineModalP">
          {#if numberOfOverduePackages != 0}指定日時を超過している荷物
          {/if}
          {#if numberOfOverduePackages != 0 && numberOfTodayPackages != 0}・{/if}
          {#if numberOfTodayPackages != 0}
            本日指定の荷物{/if}が含まれているため、考慮して配達順を決めてください。配達リストの荷物は長押しで並び替え可能です。
        </p>
        <div class="deadlineList">
          <ul>
            {#if numberOfOverduePackages != 0}
              <li>
                <span class="listLeftArea"> 指定日時超過 </span>
                <span>{numberOfOverduePackages}件</span>
              </li>
            {/if}
            {#if numberOfTodayPackages != 0}
              <li>
                <span class="listLeftArea"> 本日指定 </span>
                <span>{numberOfTodayPackages}件</span>
              </li>
            {/if}
          </ul>
        </div>
        <div class="modalButtonArea">
          <Button
            style="color: #fff; background-color: #018786; width: 100px;"
            on:click={updateStatuses}>OK</Button
          >
        </div>
        <button
          class="modalCloseButton"
          on:click={closeDeadlineAndDesiredDateDialog}
          ><span class="material-icons md-18"> close </span></button
        >
      </div>
    </div>
    <div class="modalBackground" />
  {/if}

  <!-- 住所誤りの地図確認ダイアログ -->
  <div class="googleMapDialog wideWidthMdcDialog">
    <ConfirmDialog
      bind:this={googleMapDialog}
      type={ConfirmDialogTypes.CLOSE}
      onDialogClosedHandler={() => {
        qrCodeScanner.pauseOrResumeScanning(false);
      }}
    >
      <svelte:fragment slot="title">住所確認</svelte:fragment>
      <svelte:fragment slot="content">
        <p>{googleMapAddress}</p>
        <iframe
          title="googlemap"
          src={googleMapUrl}
          width="100%"
          height="350"
          frameborder="0"
          style="border:0;"
          referrerpolicy="no-referrer"
          use:disposeIframe
        />
      </svelte:fragment>
    </ConfirmDialog>
  </div>

  <!-- ヘルプダイアログ -->
  <div class="helpDialog">
    <ConfirmDialog
      bind:this={helpDialog}
      type={ConfirmDialogTypes.CLOSE}
      onDialogClosedHandler={() => {
        if (isScanningMode) qrCodeScanner.pauseOrResumeScanning(false);
      }}
    >
      <svelte:fragment slot="title">ヘルプ</svelte:fragment>
      <svelte:fragment slot="content">
        <h3>持ち出しの取消方法</h3>
        <p>削除したい行を左にスワイプしてください。</p>
        <img
          src={deleteBringOutPackage}
          alt="取り消したい荷物の行を左にスワイプする"
          style="width: 100%;"
        />
      </svelte:fragment>
    </ConfirmDialog>
  </div>
</div>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  .countArea {
    position: absolute;
    top: 46px;
    left: 6px;

    .countLabel {
      display: inline-block;
      background-color: #fff;
      padding: 6px 8px 4px;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      opacity: 0.9;
    }

    .badge {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 15px;
      font-size: 14px;
      box-sizing: border-box;
      margin-left: 4px;
      color: #fff;
      background-color: #2ab474;
    }
  }

  .resultArea {
    flex: 1;
    width: 100%;
    background-color: #fff;
    overflow-y: auto;
    line-height: 1.36;

    :global(
      .smui-accordion
        .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z1::before
    ) {
      box-shadow: none;
    }
  }

  .resultLineWrapper {
    position: relative;
    z-index: 0;
  }

  .resultLineBackground {
    display: flex;
    align-items: center;
    gap: 2px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0%, -50%);
    color: #fff;
    z-index: -1;

    .label {
      font-size: 14px;
    }

    .material-icons {
      font-size: 20px;
    }
  }

  .resultLine {
    border-bottom: 1px solid #ccc;
    position: relative;
    z-index: 1;
    background-color: #fff;
    box-sizing: border-box;
    min-height: 58px;
  }

  .resultLine.notAllowedToBringOut {
    background-color: #ffe7e7;
  }

  .resultLineInner {
    display: flex;
    align-items: center;
    padding: 3px 8px;
  }

  .resultLeftArea {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin-right: auto;

    .resultLineInfo {
      padding: 5px 5px 0 5px;
      margin-left: 5px;
    }

    .resultLineInfoInner {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 3px;

      .tracking {
        .quantity {
          color: #c62800;
        }
      }
    }

    .durationLabel {
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      padding: 2px 4px;
      border-radius: 4px;
      height: fit-content;
      color: #018786;
      background-color: #e7f4f4;
      letter-spacing: 0.1em;
    }

    .addressArea {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 4px;
      margin: 2px auto 6px;

      .highlight {
        padding: 3px;
        border-radius: 4px;
        background-color: #facfcf;
      }

      .note {
        font-size: 11.4px;
        color: #c80000;
      }
      .possibleIncorrectAddress {
        font-size: 11.4px;
        color: #c80000;
        line-height: 1;
        display: block;
      }
    }
  }

  .resultRightArea {
    width: 64px;
    margin: 0 5px;
    white-space: nowrap;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 4px;

    .button {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin: 0;
      background-color: #eee;
      border: none;
      border-radius: 50%;
    }

    :global(.toggleDetailsButton) {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }

  .directionAndDistance {
    font-size: 13px;
    text-align: center;
    color: #333;
  }

  .warningText {
    font-size: 14px;
    margin: 0 8px 8px 8px;
    background-color: #ffe7e7;
    color: #c80000;
    border-radius: 4px;
    padding: 3px 4px;

    .returnNoticeArea {
      margin: 5px 8px;
    }

    .bringOutButtonArea {
      margin-top: 8px;
      text-align: right;
    }

    :global(.mdc-form-field) {
      color: #c80000;
    }
  }

  .badgeArea {
    width: 20px;
    margin-left: 2px;
  }

  .notice {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    color: #fff;
    background-color: #2ab474;
    border-radius: 15px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .startScanArea {
    width: 100%;
    background-color: #fff;
  }

  .startScanBtn {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translateX(-50%);
    padding: 14px 30px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    width: 350px;
  }

  /* ボタンエリア */
  .buttonArea button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    font-weight: bold;
    color: #fff;
  }

  .backBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    left: 25px;
    padding: 0;
    margin: 0;
    background-color: #018786;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .badgeNumBtn {
    position: fixed;
    width: 24px;
    height: 24px;
    right: 20px;
    bottom: calc(106px + var(--app-inset-bottom-padding));
    z-index: 2;
    color: white;
    background-color: red;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
  }

  .stackedPackagesTooltip {
    position: fixed;
    width: 180px;
    right: 10px;
    bottom: calc(140px + var(--app-inset-bottom-padding));
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
    animation: fadeId 0.5s ease-in 0.5s both;
  }
  .stackedPackagesTooltip::after {
    content: "";
    position: fixed;
    right: 35px;
    bottom: calc(135px + var(--app-inset-bottom-padding));
    width: 10px;
    height: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.6);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
    transform: rotate(60deg);
  }
  @keyframes fadeId {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .confirmBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    right: 25px;
    padding: 0;
    margin: 0;
    background-color: #018786;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .disabledBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    right: 25px;
    padding: 0;
    margin: 0;
    background-color: #ccc;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  /* ダイアログ */
  .container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 12;
  }

  .modal {
    position: relative;
    width: calc(100vw - 50px);
    margin: 0 auto;
    padding: 16px 10px;
    background-color: #fff;
    box-shadow: 0 1px 2px #ccc;
    line-height: 1.4;
  }

  .modalTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }

  .modalTitleInner {
    width: 32px;
  }

  .modalTitle p {
    font-size: 20px;
    margin-top: 2px;
  }

  .modalButtonArea {
    margin: 10px auto 6px;
    text-align: center;
  }

  .modalCloseButton {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #999;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0;
    margin: 0;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 11;
  }

  /** 登録確認ダイアログ */
  .stackDialog {
    position: relative;
    z-index: 20;

    :global(.mdc-dialog__content) {
      padding: 0 15px 20px;
    }
    :global(.mdc-dialog .mdc-dialog__surface) {
      width: calc(100vw - 32px);
    }

    #stackArea {
      width: 100%;
      max-height: 80vh;
      background-color: #fff;
      box-shadow: 0 2px 2px #ddd;
      overflow-y: auto;
      overscroll-behavior: none;
    }

    .stackLine {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .resultLineInner {
        padding: 3px 5px;
      }

      .resultLineInfo {
        padding: 5px 2px 1px 2px;
        margin-left: 0;
      }

      .resultRightArea {
        margin: 0 5px 0 2px;
      }
    }
  }

  .sortModalP {
    text-align: left;
    padding: 0 10px 10px;
  }

  .sortModalSubP {
    margin-left: 10px;
    font-size: 14px;
    color: red;
  }

  .sortArea {
    width: 260px;
    border: 1px solid #8fcaca;
    margin: 0 auto 10px;
    padding: 6px;
    border-radius: 2px;
  }

  .sortArea input {
    position: relative;
    top: -2px;
    accent-color: #018786;
  }

  .sortArea label {
    display: inline-block;
    margin-top: 2px;
  }

  .recommend {
    font-size: 14px;
    color: #fff;
    background-color: #018786;
    padding: 2px 4px;
    margin-left: 2px;
  }

  .snackbarContainer {
    :global(.mdc-snackbar__label) {
      color: #bbb;
      font-size: 0.825rem;
    }

    :global(.mdc-snackbar__action:not(:disabled)) {
      color: #fff;
    }
  }

  @media (max-width: 362px) {
    .stackLine {
      .resultLineInfoInner {
        justify-content: center;
        align-items: start;
        flex-direction: column-reverse;
      }
    }
  }

  /* 期限確認ダイアログ */

  .deadlineModalP {
    text-align: left;
    padding: 0 10px 10px;
  }

  .deadlineList {
    width: fit-content;
    margin: 0 auto 24px;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 160px;
      margin-bottom: 6px;
      gap: 4px;
    }

    .listLeftArea {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 4px;
    }

    .listLeftArea::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #2ab474;
      margin-right: 4px;
    }
  }

  .toolButtonArea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 4px;
    .copyAddressButton,
    .googleMapButton {
      display: flex;
      align-items: center;
      gap: 1px;
      padding: 1px 4px;
      background-color: #fff;
      color: #018786;
      border: 1px solid #018786;
      border-radius: 4px;

      span {
        font-size: 10px;

        &.material-icons {
          font-size: 14px;
        }
      }
    }
  }

  .subContentsWrapper {
    .googleMapDialog {
      position: fixed;
      // 登録待ちの荷物のダイアログからダイアログを開いたときに上に来るようにする
      z-index: 25;
      :global(.mdc-dialog__content) {
        padding: 0;
      }
      :global(.mdc-dialog__content p) {
        padding-left: 8px;
        padding-right: 8px;
        line-height: 1;
      }
    }
  }

  /* ヘルプダイアログ */
  .helpDialog {
    position: relative;
    z-index: 20;
  }
</style>
